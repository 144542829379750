// import React, { useState } from "react";
// import Sidebar from "../../common/components/Sidebar/sidebar";
// import TimeLine from "../../common/components/TimeLine/TimeLine";
// import "react-step-progress-bar/styles.css";
// import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
// import Model from "../../common/components/model/Model";
// import FormHeader from "../../common/components/FormHeader/FormHeader";
// import DataForm from "../../common/components/FormBody/DataForm";
import Strings from "../../common/res/String";
// import { Controller, useForm } from "react-hook-form";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import moment from "moment";
// export default function EWAY() {
//   const [isCount, setIsCount] = useState(7);
//   const [isModalOpen, setModalOpen] = useState(false);
//   const { control, handleSubmit, setValue, reset, watch } = useForm();
//   const[isProcessing,setIsProcessing]=useState(false)
//   const handleDownloadAll = async () => {
//     setIsProcessing(true);
 

//     try {
//       // Convert the content of the page to PDF
//       const pageContent = document.getElementById("eway_download");
//       if (pageContent) {
//         const canvas = await html2canvas(pageContent);
//         const imgData = canvas.toDataURL("image/png");

//         // Create PDF with proper dimensions
//         const pdf = new jsPDF("p", "pt", "a4");
//         const imgProps = pdf.getImageProperties(imgData);
//         const pdfWidth = pdf.internal.pageSize.getWidth();
//         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//         const topGap = 48;
//         pdf.addImage(imgData, "PNG", 0, topGap, pdfWidth, pdfHeight);
//         pdf.save("Eway.pdf");
//         // Save the PDF directly and convert to Base64
  
//       }


//     } catch (error) {
//       console.error("Error during download:", error);
//     } finally {
//       setIsProcessing(false);
//     }
//   };
//   const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
//   console.log("retrievedData", retrievedData);
//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col-lg-2 p-0 col-md-3">
//           <Sidebar />
//         </div>

//         <div className="col-sm-10 col-lg-10">
//           <div className="row align-items-center">
//             <div className="headerTimeLine px-5">
//               <TimeLine currentStep={isCount} />
//             </div>
//           </div>
//           <div className="row" style={{ overflow: "hidden" }}>
//             <div className="col-lg-9" style={{ paddingTop: "0.5rem" }}>
//               <div id="eway_download">
//                 <div style={{ paddingLeft: "3rem" }}>
//                   <div className="data-entry-input-block mb-2">
//                     <label className="data-entry-label">
//                       {Strings.billnumber}
//                     </label>
//                     <div className="data-entry-input">
//                       <input className="data-entry-input-field --hiddens "      disabled
//                         value={retrievedData&&retrievedData?.beNo} />
//                     </div>
//                   </div>
//                   <div className="data-entry-input-block mb-2">
//                     <label className="data-entry-label">{Strings.bedate}</label>
//                     <div className="data-entry-input">
                   
//                           <input className="data-entry-input-field --hiddens" disabled
//                         value={moment(retrievedData&&retrievedData?.beDate).format("DD/MM/YYYY")} />
                     
//                     </div>
//                   </div>
//                   <div className="data-entry-input-block mb-2">
//                     <label className="data-entry-label">
//                       {Strings.cfsname}
//                     </label>
//                     <div className="data-entry-input">
                
//                           <input className="data-entry-input-field --hiddens" disabled
//                         value={retrievedData&&retrievedData?.cfsName?retrievedData&&retrievedData?.cfsName:"NULL"} />
                    
//                     </div>
//                   </div>
//                   <div className="data-entry-input-block mb-2">
//                     <label className="data-entry-label">
//                       {Strings.dutypaydate}
//                     </label>
//                     <div className="data-entry-input">
                  
//                     <input className="data-entry-input-field --hiddens" disabled
//                         value={moment(retrievedData&&retrievedData?.dutyPaymentDate).format("DD/MM/YYYY")} />
                     
//                     </div>
//                   </div>
//                   <div className="data-entry-input-block mb-2">
//                     <label className="data-entry-label">
//                       {Strings.exporter}
//                     </label>
//                     <div className="data-entry-input">
//                     <input className="data-entry-input-field --hiddens" disabled
//                         value={retrievedData&&retrievedData?.exporter?retrievedData&&retrievedData?.exporter:"NULL"} />
                    
//                     </div>
//                   </div>
//                 </div>
            
//                   <div className="eway_table_scroll" style={{ paddingLeft: "2rem" }}>
//                     <table className="ewaytable">
//                       <thead className="eway_table_header">
//                         <tr className="eway_tablerow">
//                           <th className="eway_table_headerlabel">S.no</th>
//                           <th className="eway_table_headerlabel">
//                           CONTAINER NO
//                           </th>
//                           <th className="eway_table_headerlabel">NOS</th>
//                           <th className="eway_table_headerlabel">NET CBM</th>
//                           <th className="eway_table_headerlabel">RATE</th>
//                           <th className="eway_table_headerlabel">AMT</th>
//                           <th className="eway_table_headerlabel">TAXABLE</th>
//                           <th className="eway_table_headerlabel">LORRY NO</th>
//                           <th className="eway_table_headerlabel">AVG CFT/ AVG GIRTH</th>
                          
//                         </tr>
//                       </thead>
//                       <tbody className="">
//                         {retrievedData&&retrievedData?.userForm
//                           .map((ele, index) => (
//                             <tr key={index} className="">
//                               <td className="eway_table_bodylabel">{index+1}</td>
//                               <td className="eway_table_bodylabel">
//                                {ele?.containerNo}
//                               </td>
//                               <td className="eway_table_bodylabel">{ele?.sellRate}</td>
//                               <td className="eway_table_bodylabel">{ele?.netCbm}</td>
//                               <td className="eway_table_bodylabel">{ele?.avgRate}</td>
//                               <td className="eway_table_bodylabel">{ele?.amt}</td>
//                               <td className="eway_table_bodylabel">
//                                {ele?.grossCbm}
//                               </td>
//                               <td className="eway_table_bodylabel">
//                              {ele?.lorry}
//                               </td>
//                               <td className="eway_table_bodylabel">
//                              {ele?.avgCftAvgGirth}
//                               </td>
//                             </tr>
//                           ))}
//                       </tbody>
//                     </table>
//                   </div>
             
//               </div>
//               <div
//                 style={{
//                   textAlign: "center",
//                   paddingTop: "1rem",
//                   paddingLeft: "3rem",
//                   marginLeft: "3rem",
//                 }}
//               >
//                 <button className="btn_download_file"   onClick={() => {
                   
//                         handleDownloadAll()
//                       }}
//                     >{
//                       isProcessing?"Loading....":"Download"
//                     }</button>
//               </div>
            
//             </div>
//             <div className="col-lg-3 p-0 right_col">
//               <div className="headerTimeLine_left">
//                 {/* <SimpleBar style={{ maxHeight: 300 }}> */}

//                 <RightSidebar setIsCount={setIsCount} islabel="EWAY" />
//                 {/* </SimpleBar> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import "react-step-progress-bar/styles.css";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

export default function EWAY() {
  const [isCount, setIsCount] = useState(8);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDownloadAll = async () => {
    setIsProcessing(true);

    try {
      const pageContent = document.getElementById("eway_download");
      if (pageContent) {
        const canvas = await html2canvas(pageContent);
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // let position = 0;
        const topGap = 48;
        pdf.addImage(imgData, "PNG", 0, topGap, pdfWidth, pdfHeight);

        pdf.save("Eway.pdf");
      }
    } catch (error) {
      console.error("Error during download:", error);
      alert("An error occurred while generating the PDF. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2 p-0 col-md-3">
          <Sidebar />
        </div>

        <div className="col-sm-10 col-lg-10">
          <div className="row align-items-center">
            <div className="headerTimeLine px-5">
              <TimeLine currentStep={isCount} />
            </div>
          </div>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-lg-9" style={{ paddingTop: "0.5rem" }}>
              <div id="eway_download">
                <div style={{ paddingLeft: "3rem" }}>
                  <div className="data-entry-input-block mb-2">
                    <label className="data-entry-label">
                      {Strings.billnumber}
                    </label>
                    <div className="data-entry-input">
                      <input
                        className="data-entry-input-field --hiddens"
                        disabled
                        value={retrievedData && retrievedData?.beNo}
                      />
                    </div>
                  </div>
                  <div className="data-entry-input-block mb-2">
                    <label className="data-entry-label">{Strings.bedate}</label>
                    <div className="data-entry-input">
                      <input
                        className="data-entry-input-field --hiddens"
                        disabled
                        value={moment(retrievedData && retrievedData?.beDate).format("DD/MM/YYYY")}
                      />
                    </div>
                  </div>
                  <div className="data-entry-input-block mb-2">
                    <label className="data-entry-label">{Strings.cfsname}</label>
                    <div className="data-entry-input">
                      <input
                        className="data-entry-input-field --hiddens"
                        disabled
                        value={retrievedData && retrievedData?.cfsName ? retrievedData?.cfsName : "NULL"}
                      />
                    </div>
                  </div>
                  <div className="data-entry-input-block mb-2">
                    <label className="data-entry-label">{Strings.dutypaydate}</label>
                    <div className="data-entry-input">
                      <input
                        className="data-entry-input-field --hiddens"
                        disabled
                        value={moment(retrievedData && retrievedData?.dutyPaymentDate).format("DD/MM/YYYY")}
                      />
                    </div>
                  </div>
                  <div className="data-entry-input-block mb-2">
                    <label className="data-entry-label">{Strings.exporter}</label>
                    <div className="data-entry-input">
                      <input
                        className="data-entry-input-field --hiddens"
                        disabled
                        value={retrievedData && retrievedData?.exporter ? retrievedData?.exporter : "NULL"}
                      />
                    </div>
                  </div>
                </div>

                <div className="eway_table_scroll" style={{ paddingLeft: "2rem" }}>
                  <table className="ewaytable">
                    <thead className="eway_table_header">
                      <tr className="eway_tablerow">
                        <th className="eway_table_headerlabel first_val">S.no</th>
                        <th className="eway_table_headerlabel sec_val ">CONTAINER NO</th>
                        <th className="eway_table_headerlabel three_val">NOS</th>
                        <th className="eway_table_headerlabel four_val">NET CBM</th>
                        <th className="eway_table_headerlabel five_val">RATE</th>
                        <th className="eway_table_headerlabel six_val">AMT</th>
                        <th className="eway_table_headerlabel seveen_val">TAXABLE</th>
                        <th className="eway_table_headerlabel eig_val">LORRY NO</th>
                        <th className="eway_table_headerlabel nine_val">AVG CFT/ AVG GIRTH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {retrievedData && retrievedData?.userForm.map((ele, index) => (
                        <tr key={index}>
                          <td className="eway_table_bodylabel">{index + 1}</td>
                          <td className="eway_table_bodylabel">{ele?.containerNo}</td>
                          <td className="eway_table_bodylabel">{ele?.sellRate}</td>
                          <td className="eway_table_bodylabel">{ele?.netCbm}</td>
                          <td className="eway_table_bodylabel">{ele?.avgRate}</td>
                          <td className="eway_table_bodylabel">{ele?.amt}</td>
                          <td className="eway_table_bodylabel">{ele?.grossCbm}</td>
                          <td className="eway_table_bodylabel">{ele?.lorry}</td>
                          <td className="eway_table_bodylabel">{ele?.avgCftAvgGirth}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ textAlign: "center", paddingTop: "1rem", paddingLeft: "3rem", marginLeft: "3rem" }}>
                <button
                  className="btn_download_file"
                  onClick={() => {
                    handleDownloadAll();
                  }}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Loading...." : "Download"}
                </button>
              </div>
            </div>
            <div className="col-lg-3 p-0 right_col">
              <div className="headerTimeLine_left">
                <RightSidebar setIsCount={setIsCount} islabel="EWAY" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
