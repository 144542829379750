import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useRef, useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import SblcConfirm from "../../common/components/sblcComponent/SblcConfirm";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import FormA1Form from "../../common/FormA1Component/FormA1Form";
import Demo from "../../TestDemo/Demo";

export default function FormA1() {
  const [isCount, setIsCount] = useState(3);
  // const formA1Ref = useRef([]);
  // const sectionsRef = useRef([]);
  // const page2Element = document.getElementById("formA1_Screen");
  // console.log("page2Element", page2Element);

  // const handleDownload = () => {
  //   const page2Element = document.getElementById("formA1_Screen");
  //   html2canvas(page2Element, { scale: 2 }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pdfWidth;

  //     const imgHeight = (canvas.height * pdfWidth) / canvas.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, pdfHeight);
  //     pdf.save("Form A1.pdf");
  //   });
  // };


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-sm-10 col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row" style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="statusWaiverMainContainer">
                  <div id="formA1_Screen">
                  {/* <Demo/> */}
                  <FormA1Form />
                  </div>
                  {/* <div className="d-flex justify-content-center py-5">
                    <button
                      className="summary-download-btn"
                      // onClick={() => handleDownload()}
                    >
                      Download
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel={"FORM A1"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
