import React, { useEffect, useState } from "react";
import FormFooter from "../FormFooter/FormFooter";
import Images from "../../assets";
import DownloadModel from "../downloadModel/DownloadModel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useGlobalState } from "../../GlobalProvider/GlobalStateProvider";
import moment from "moment";

const StatusWaiver = ({ isCount }) => {
  // const handleDownload = () => {
  //   if (isCount === 1) {
  //     downloadFile('page1.pdf');
  //   } else if (isCount === 2) {
  //     downloadFile('page1.pdf');
  //     downloadFile('page2.pdf');
  //   }
  // };
  const downloadFile = (fileName) => {
    // Assuming the files are stored in the 'public' directory or accessible via a URL
    // const fileUrl = `${process.env.PUBLIC_URL}/${fileName}`;
    //  const fileUrl;
    //   const link = document.createElement('a');
    //   link.href = fileUrl;
    //   link.download = fileName;
    //   link.click();
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const openModal = () => {
  //   handleDownload()
  //     // setModalIsOpen(true);
  // };
  const { setPageContent, page1Content, page2Content } = useGlobalState();

  useEffect(() => {
    const capturePage3 = async () => {
      const page2Element = document.getElementById("StatusWaiver_Screen");
      const canvas = await html2canvas(page2Element);
      setPageContent("page2Content", canvas.toDataURL("image/png"));
    };

    capturePage3();
  }, [setPageContent]);

  const downloadPDF = (content, fileName) => {
    const pdf = new jsPDF("p", "pt", "a4");
    const imgProps = pdf.getImageProperties(content);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(content, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(fileName);
  };
  const handleDownloadPDF = async () => {
    if (page1Content) {
      downloadPDF(page1Content, "SBLC.pdf");
    }
    if (page2Content) {
      downloadPDF(page2Content, "Status.pdf");
    }
  };
  let sessionData = JSON.parse(sessionStorage.getItem("formdata"));
  // console.log("SESSION DATA", sessionData);

  return (
    <>
      <div id="StatusWaiver_Screen">
        <div className="status-waiver-block pt-2">
          <div className="px-4" style={{ height: "1123px" }}>
            <div className="status-logo py-1 ">
              <img
                src={Images.logo}
                alt="logo"
                style={{ height: "90%", width: "30%" }}
              />
            </div>
            <div className="d-flex flex-column pt-1" style={{ gap: "19px" }}>
              <div
                className="d-flex justify-content-between"
                style={{ gap: "19px" }}
              >
                <span className="status-waiver-content">Letter No.</span>
                <span className="status-waiver-content">Date : {moment().format("DD.MM.YYYY")}</span>
              </div>
              <div className="d-flex flex-column" style={{ gap: "19px" }}>
                <span className="status-waiver-content">To</span>
                <span className="status-waiver-content">
                  The Branch Manager
                </span>
                <span className="status-waiver-content">Branch : </span>
                <span className="status-waiver-content">
                  The South Indian Bank Ltd
                </span>
              </div>

              <div className="pt-2 d-flex flex-column" style={{ gap: "19px" }}>
                <span className="status-waiver-content">Sir/Madam</span>
                <span className="status-waiver-content">
                  Sub: Waiver of Status Report- for import and opening SBLC{" "}
                </span>
                <span className="status-waiver-content">
                  Ref : Payment towards import and Opening SBLC for Imports
                </span>
                <span className="status-waiver-content --para">
                  Remittance Amount: FCY USD{" "}
                  {sessionData && sessionData?.bgAmtAndInterestAmt}{" "}
                  Favouring:&nbsp;
                  {sessionData && sessionData?.exporter}
                  F.Z.E Invoice Number {sessionData && sessionData?.invoiceNo}
                  &nbsp; dated{" "}
                  {moment(sessionData && sessionData?.invoiceDt).format(
                    "DD/MM/YYYY"
                  )}
                  &nbsp; for FCY USD
                  {sessionData && sessionData?.bgAmtAndInterestAmt}
                </span>
                <span className="status-waiver-content">
                  With reference to the aforesaid Payment towards import and
                  Opening SBLC for Imports favouring M/S ,ON A/C OF{" "}
                  {sessionData && sessionData?.exporter}{" "}
                  {sessionData && sessionData?.address} for FCY USD{" "}
                  {sessionData && sessionData?.bgAmtAndInterestAmt}
                  submitted by us on 00.00.2024, we hereby inform that we:
                </span>
                <span className="status-waiver-content --listPara">
                  1. Have been dealing with this supplier for the past 1 years
                  and we are confident of contract performance by them/
                </span>
                <span className="status-waiver-content --listPara">
                  2. The supplier comes to us through trusted references and
                  hence we are fully confident about their ability to perform.
                  (Strike off whichever is not applicable).
                </span>
                <span className="status-waiver-content --listPara">
                  3. We have made our own enquiry and is satisfied regarding the
                  suppliers credentials. We have also confirmed the genuinity of
                  the above invoice and the bank account details provided there
                  in
                </span>
                <span className="status-waiver-content">
                  In light of the same, we request you to kindly process import
                  bill in favour of {sessionData && sessionData?.exporter}{" "}
                  F.Z.E, as we are convinced about the credibility of the
                  supplier.
                </span>
                <span className="status-waiver-content">
                  We also wish to inform that we expect the shipment to be
                  completed by{" "}
                  {moment(sessionData && sessionData?.dateOfShipping).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  and will be submitting the Bill of Entry Number and Date
                  within reasonable time of taking delivery and in any case,
                  within 90 days from date of remittance/opening of LC.
                </span>
                <span className="status-waiver-content">
                  Request you to kindly process the payment/LC opening request
                  without obtaining satisfactory credit report as we assume all
                  risk and responsibility for likely losses.
                </span>
                <span className="status-waiver-content pt-3">
                  Yours faithfully
                </span>
                <span className="status-waiver-content --content-border pt-3 mb-1">
                  Authorised Signatory
                </span>
              </div>
            </div>
          </div>

          <FormFooter />
        </div>
      </div>
      <div className="d-flex justify-content-center py-5">
        {/* <button  className="open-modal-btn">Open Modal</button> */}
        <button onClick={handleDownloadPDF} className="summary-download-btn">
          Download
        </button>
      </div>
      {/* <DownloadModel isOpen={modalIsOpen} onRequestClose={closeModal} /> */}
    </>
  );
};

export default StatusWaiver;
