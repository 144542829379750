import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function RightSidebar({ setIsCount, islabel }) {
  const [isActive, setIsActive] = useState();
  console.log("isActive", isActive);

  const navigate = useNavigate();
  const btn_label = [
    "COPY DOCUMENT",
    "SBLC",
    "FORM A1",
    "STATUS WAIVER",

    "FORWARD CONTRACT",
    "CLEARING CHARGES",
    "SUMMARY",
    "EWAY",
    "P&L",
    "FILE DOCUMENT"
  ];
  const [isLabel, setIsLabel] = useState();
  const handlerChanges = (ele) => {
    let count = btn_label.findIndex((la) => la == ele);
    setIsActive(ele);
    if (ele === "COPY DOCUMENT") {
      setIsActive(ele);
      navigate("/CopyDocumentConfirm", { state: isLabel });
    } else if (ele === "STATUS WAIVER") {
      navigate("/statusWaiverConfirm");
    } else if (ele === "SBLC") {
      navigate("/sblc");
      setIsActive(ele);
    } else if (ele === "FORM A1") {
      navigate("/formA1");
      setIsActive(ele);
    } else if (ele === "FORWARD CONTRACT") {
      navigate("/ForwardContractScreen", { state: isLabel });
    } else if (ele === "EWAY") {
      setIsActive(ele);
      navigate("/Eway");
    } else if (ele === "SUMMARY") {
      navigate("/summary");
      setIsActive(ele);
    } else if (ele === "P&L") {
      navigate("/PL");
      setIsActive(ele);
    }
     else if (ele === "CLEARING CHARGES") {
      navigate("/clearingCharges");
      setIsActive(ele);
    }
     else if (ele === "FILE DOCUMENT") {
      navigate("/FileDocument");
      setIsActive(ele);
    }
    setIsCount(count + 1);
  };
  console.log("isActive", isActive);
  return (
    <>
      {btn_label.map((ele, i) => {
        return (
          <div
            className={`btn_labels ${islabel == ele ? "active_home" : ""}`}
            onClick={() => handlerChanges(ele)}
          >
            <p className="label_sidebar_right">{ele}</p>
          </div>
        );
      })}
    </>
  );
}
