import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Strings from "../../res/String";
import InputField from "../inputField/InputField";
import Model from "../model/Model";
import Images from "../../assets";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
const AddUserForm = () => {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const navigate = useNavigate();
  const [showpassword, setShowPassword] = useState(false);
  const [confirmPassword,setConfirmPassword]=useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  // const { password, confirmpassword } = watch();

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/users`, postData),
    {
      onSuccess: () => {
        console.log("user added successfully..");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    let formData = {
      userId: data?.userId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNumber: data?.mobileNumber,
      emailid: data?.emailId,
      role: data?.role,
      password: data?.password,
      confirmpassword: data?.confirmpassword,
      status: data?.status,
    };
    console.log("formData", formData);
    if (formData) {
      mutate(formData);
    }
  };
  const togglePassword = () => {
    setShowPassword(!showpassword);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-3 py-4">
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.userid}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="userId"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="3421631"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.firstname}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="firstName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="PRASANTH"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.lastname}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="lastName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="A"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.mobilenumber}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="mobileNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="7010489435"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.emailid}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="emailId"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="email"
                    placeholder="prasanth@virtualworld.com"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.password}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type={showpassword ? "text" : "password"}
                    placeholder="********"
                  />
                )}
              />
              {showpassword ? (
                <img
                  src={Images.showpasswords}
                  className="image_icon --password-icon"
                  onClick={togglePassword}
                />
              ) : (
                <img
                  src={Images.hidepasswords}
                  className="image_icon --password-icon"
                  onClick={togglePassword}
                />
              )}
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">ROLE</label>
            <div className="data-entry-input --add-user-input">
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select {...field} className="data-entry-input-field">
                    <option value="">SELECT ROLE</option>
                    <option value="admin">Admin</option>
                    <option value="superAdmin">Super Admin</option>
                  </select>
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.confirmpassword}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="confirmpassword"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type={confirmPassword ? 'text' : 'password'}
                    placeholder="********"
                  />
                )}
              />
               
                <img
                  src={confirmPassword?Images.showpasswords:Images?.hidepasswords}
                  className="image_icon --password-icon"
                  onClick={()=>{
                    setConfirmPassword(!confirmPassword)
                  }}
                />
             
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">STATUS</label>
            <div className="data-entry-input --add-user-input">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select {...field} className="data-entry-input-field">
                    <option value="">SELECT STATUS</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                )}
              />
            </div>
          </div>
        </div>
        <div className="addUserBtnContainer text-center py-3">
          <button className="add-user-submit-btn" onClick={togglePopup}>
            Submit
          </button>
        </div>
      </form>

      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-2">
          <h6 className="popup-content mb-3">
            User has been added successfully
          </h6>
          <button
            className="user-add-button mt-2 mb-2"
            onClick={() => {
              navigate("/userManagement");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddUserForm;
