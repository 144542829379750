
import React from 'react';
import Modal from 'react-modal';
// import './ModalPage.css';
import Images from '../../assets';

Modal.setAppElement('#root');

const DownloadModel = ({ isOpen, onRequestClose }) => {
    return (
        <Modal 
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Model Page"
            className="Modal"
            overlayClassName="Overlay"
        >
            <div className="model-container">
                <div className="model-header">
                    <img src={Images.logo} alt="Best Timbers Logo" className="logo" />
                    <div className="file-name">NAME.file_name</div>
                </div>
                <div className="model-content">
                    <img src={Images.bg} alt="Content Image" className="center-image" />
                </div>
                <div className="model-footer">
                    <button className="btn download">DOWNLOAD</button>
                    <button className="btn cancel" onClick={onRequestClose}>CANCEL</button>
                    <button className="btn share">SHARE</button>
                </div>
            </div>
        </Modal>
    );
};

export default DownloadModel;
