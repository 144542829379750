import React, { useRef } from "react";

const FileUploadContainer = ({
  file,
  onFileChange,
  onRemove,
  name,
  statepdf,
}) => {
  const fileInputRef = useRef(null);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    onFileChange(event);
  };

  return (
    <div style={{ marginBottom: "20px", position: "relative" }}>
      <input
        type="file"
        accept=".pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <div
        className="dataEntryAddCopyDocument my-4"
        onClick={handleFileInputClick}
      >
        <div className="dataEntryAddInnerCopyDocument">
          <h6 className="data-entry-image-head">ADD {name}</h6>
        </div>
      </div>

      {(statepdf || file) && (
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "10px" }}
        >
          <p style={{ margin: 0 }}>{statepdf ? `${name}.pdf` : file?.name}</p>
          <button
            type="button"
            onClick={onRemove}
            className="file-remove-button"
            style={{
              marginLeft: "10px",
              backgroundColor: "red",
              color: "white",
            }}
          >
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUploadContainer;