import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import Strings from "../../common/res/String";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import Model from "../../common/components/model/Model";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const ClearingCharges = () => {
  const [isCount, setIsCount] = useState(6);
  const [isModalOpen, setModalOpen] = useState(false);
  const formdataValuse = sessionStorage.getItem("formdata");
  const formArrayValue = JSON.parse(formdataValuse);
  console.log("formArrayValue", formArrayValue.userForm);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    // Perform delete operation
    console.log("User detail deleted");
    setModalOpen(false);
  };
  const [isProcessing, setIsProcessing] = useState(false);
  const handleDownloadAll = async () => {
    setIsProcessing(true);

    try {
      // Convert the content of the page to PDF
      const pageContent = document.getElementById("clear_changess");
      if (pageContent) {
        const canvas = await html2canvas(pageContent);
        const imgData = canvas.toDataURL("image/png");

        // Create PDF with proper dimensions
        const pdf = new jsPDF("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const topGap = 48;
        pdf.addImage(imgData, "PNG", 0, topGap, pdfWidth, pdfHeight);
        pdf.save("CearingChanges.pdf");
        // Save the PDF directly and convert to Base64
      }
    } catch (error) {
      console.error("Error during download:", error);
    } finally {
      setIsProcessing(false);
    }
  };
  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  // console.log("retrievedData", retrievedData.pqRelax);
  console.log("RETRIVED DATA", retrievedData);
  let pqAmount = (Number(retrievedData?.pqWeight) - 1) * 200 + 3500;
  console.log("Pqamount", pqAmount);
  let fumigationCh =
    retrievedData && retrievedData?.fumigationCharges
      ? retrievedData?.fumigationCharges
      : 0;
  let container =
    retrievedData && retrievedData?.containersNumber
      ? retrievedData?.containersNumber
      : 0;

  let cfscharge =
    retrievedData && retrievedData?.cfsCharges ? retrievedData?.cfsCharges : 0;
  let examination =
    retrievedData && retrievedData?.examinationCharge
      ? retrievedData?.examinationCharge
      : 0;

  let sec = retrievedData && retrievedData?.sec49 ? retrievedData?.sec49 : 0;
  let cfsground =
    retrievedData && retrievedData?.cfsGroundRent
      ? retrievedData?.cfsGroundRent
      : 0;
  let cfssurvey =
    retrievedData && retrievedData?.chaSurvey ? retrievedData?.chaSurvey : 0;

  const formData = [
    {
      description: "PQ CHARGES",
      // NOS:2,
      NOS:
        retrievedData && retrievedData?.pqWeight ? retrievedData?.pqWeight : 0,
      RATE: "",
      AMT: pqAmount,
    },
    {
      description: "PQ RELAX",
      // NOS:2,
      NOS: "",
      // RATE:retrievedData && retrievedData?.pqRelax ? retrievedData?.pqRelax : 0,
      RATE: "",
      AMT:
        retrievedData && retrievedData?.pqFumigation
          ? retrievedData?.pqFumigation
          : 0,
    },
    {
      description: "PQ 4 TIMES",
      // NOS:2,
      NOS: "",
      RATE: "",
      AMT: pqAmount * 4,
    },
    {
      description: "FUMI CHARGES",
      NOS:
        retrievedData && retrievedData?.containersNumber
          ? retrievedData?.containersNumber
          : 0,

      // NOS:"-",
      // RATE:retrievedData && retrievedData?.fumigationCharges ? retrievedData?.fumigationCharges : '-',
      RATE:
        retrievedData && retrievedData?.fumigationCharges
          ? retrievedData?.fumigationCharges
          : 0,
      AMT: (fumigationCh * container && fumigationCh * container) || 0,
    },
    {
      description: "CFS",
      NOS:
        retrievedData && retrievedData?.containersNumber
          ? retrievedData?.containersNumber
          : 0,
      // NOS:'-',
      // RATE:retrievedData && retrievedData?.cfsCharges ? retrievedData?.cfsCharges : '-',
      RATE:
        retrievedData && retrievedData?.cfsCharges
          ? retrievedData?.cfsCharges
          : 0,
      AMT: (cfscharge * container && cfscharge * container) || 0,
    },
    {
      description: "CHA & SURVEY",
      NOS:
        retrievedData && retrievedData?.containersNumber
          ? retrievedData?.containersNumber
          : 0,
      // NOS:"-",
      // RATE:"-",
      RATE:
        retrievedData && retrievedData?.chaSurvey
          ? retrievedData?.chaSurvey
          : 0,
      AMT: (container * cfssurvey && container * cfssurvey) || 0,
    },
    {
      description: "EXAMINATION CHARGES",
      NOS:
        retrievedData && retrievedData?.containersNumber
          ? retrievedData?.containersNumber
          : 0,
      // NOS:'-',
      // RATE:retrievedData && retrievedData?.examinationCharges ? retrievedData?.examinationCharges : '-',
      RATE:
        retrievedData && retrievedData?.examinationCharge
          ? retrievedData?.examinationCharge
          : 0,
      AMT: (container * examination && container * examination) || 0,
    },
    {
      description: "CFS GROUND RENT",
      NOS:
        retrievedData && retrievedData?.cfsGroundRent
          ? retrievedData?.cfsGroundRent
          : 0,
      // NOS:'-',
      // RATE:retrievedData && retrievedData?.cfsGroundRent ? retrievedData?.cfsGroundRent : '-',
      RATE:
        retrievedData && retrievedData?.cfsGroundRent
          ? retrievedData?.cfsGroundRent
          : 0,
      AMT: cfsground * 1500,
    },
    {
      description: "SEC49",
      NOS:
        retrievedData && retrievedData?.containersNumber
          ? retrievedData?.containersNumber
          : 0,
      // NOS:'-',
      // RATE:retrievedData && retrievedData?.sec49 ? retrievedData?.sec49 : '-',
      RATE: retrievedData && retrievedData?.sec49 ? retrievedData?.sec49 : 0,
      AMT: (container * sec && container * sec) || 0,
    },
  ];
  let nosTotal = 0;
  let rateTotal = 0;
  let nosRateTotal = 0;
  let totalAmt = 0;
  let gstTotal = 0;
  let gstTotalAmt = 0;
  let roundOffTotal = 0;
  let tds = 0;
  let grandTotal = 0;

  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row " style={{ paddingLeft: "3rem" }}>
              <div
                className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center"
                style={{ height: "auto", overflow: "hidden" }}
              >
                <div className="summaryMainContainer_laas p-0 m-0">
                  <div id="clear_changess">
                    <div className="summary-head px-4">
                      <label className="summary-label">{Strings.beno}</label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          disabled
                          value={retrievedData && retrievedData?.beNo}
                        />
                      </div>
                    </div>
                    <div className="summary-table-container mt-2 px-4 pt-3">
                      <table className="summary-table">
                        <thead className="summary-table-head">
                          <tr>
                            <th>S.no</th>
                            <th>DESCRIPTION</th>
                            <th>NOS</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                            {/* <th>CBM</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.map((val, index) => {
                            nosTotal = Number(val.NOS) + nosTotal;
                            rateTotal = Number(val.RATE) + rateTotal;
                            nosRateTotal = Number(val.NOS) * Number(val.RATE);
                            console.log("nosRateTotal", nosRateTotal);
                            totalAmt = Number(val?.AMT) + Number(totalAmt);
                            if (index > 2) {
                              gstTotal =
                                ((val?.AMT + Number(gstTotal)) * 18) / 100;

                              tds = ((val?.AMT + Number(tds)) * 2) / 100;
                            }
                            gstTotalAmt = totalAmt + gstTotal;
                            roundOffTotal =
                              Number(gstTotalAmt) -
                              Number(retrievedData && retrievedData?.roundOff);

                            grandTotal = roundOffTotal - tds;
                            return (
                              <tr key={index}>
                                <td className="summary_table_body">
                                  {index + 1}
                                </td>
                                <td className="summary_table_body">
                                  {val.description}
                                </td>
                                <td className="summary_table_body">
                                  {val.NOS}
                                </td>
                                <td className="summary_table_body">
                                  {val.RATE}
                                </td>
                                <td className="summary_table_body">
                                  {val?.AMT}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="summary-head px-4 pt-3">
                      <label className="summary-label">
                        {Strings.totalamt}
                      </label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          disabled
                          value={totalAmt}
                        />
                      </div>
                    </div>
                    <div className="summary-head px-4">
                      <label className="summary-label">{Strings.gst}</label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          disabled
                          value={gstTotal}
                        />
                      </div>
                    </div>
                    <div className="summary-head px-4">
                      <label className="summary-label">
                        {Strings.roundoff}
                      </label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          value={retrievedData && retrievedData?.roundOff}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="summary-head px-4">
                      <label className="summary-label">{Strings.total}</label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          disabled
                          value={gstTotal + totalAmt}
                        />
                      </div>
                    </div>
                    <div className="summary-head px-4">
                      <label className="summary-label">{Strings.TDS}</label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          value={tds}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="summary-head px-4">
                      <label className="summary-label">{Strings.total}</label>
                      <div style={{ width: "35%" }}>
                        <input
                          type="search"
                          name="beno"
                          className="billNoSearchField --hiddens"
                          placeholder="3421631"
                          value={gstTotal + totalAmt - tds}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="summary-download">
                    <button
                      className="summary-download-btn"
                      onClick={() => {
                        setModalOpen(true);
                        handleDownloadAll();
                      }}
                    >
                      {isProcessing ? "Loading...." : "Download"}
                    </button>
                    {/* <Model
                      open={isModalOpen}
                      handleClose={handleCloseModal}
                      handleConfirm={handleConfirmModal}
                    ></Model> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar
                    setIsCount={setIsCount}
                    islabel="CLEARING CHARGES"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearingCharges;
