import React, { useEffect, useState } from "react";
import InputField from "../inputField/InputField";
import { Controller, useForm } from "react-hook-form";
import Strings from "../../res/String";
import { useNavigate } from "react-router-dom";
import Model from "../model/Model";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";
import { useLocation } from "react-router-dom";
import axios from "axios";

const ViewUserForm = () => {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const { state } = useLocation();

  let value = state;
  console.log("VALUE", value);

  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  const deletetogglePopup = async (state) => {
    setDeletePopUp(!deletePopUp);
    // console.log('deletestate', state)
    try {
      await axios.delete(
        `${process.env.REACT_APP_HOST}/users/${state?.id}`
      );
    } catch (error) {
      console.log("Error", error);
    }
    setPopupOpen(false);
  };
  useEffect(() => {
    setValue("userId", state && state?.userId);
    setValue("firstName", state && state?.firstName);
    setValue("mobileNumber", state && state?.mobileNumber);
    setValue("emailId", state && state?.emailid);
    setValue("role", state && state?.role);
    setValue("status", state && state?.status);
    setValue("lastName", state && state?.lastName);
  }, []);
  const onSubmit = (data) => {
    console.log("formData", data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-3 py-4">
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.userid}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="userId"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="3421631"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.firstname}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="firstName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="PRASANTH"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.lastname}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="lastName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="A"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.mobilenumber}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="mobileNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="7010489435"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.emailid}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="emailId"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="email"
                    placeholder="prasanth@virtualworld.com"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.password}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="password"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="password"
                    placeholder="********"
                  />
                )}
              />
              <img />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">ROLE</label>
            <div className="data-entry-input --add-user-input">
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    {...field}
                    className="data-entry-input-field --select-option"
                  >
                    <option value="">SELECT ROLE</option>
                    <option value="admin">Admin</option>
                    <option value="superAdmin">Super Admin</option>
                  </select>
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.confirmpassword}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="confirmPassword"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="password"
                    placeholder="********"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">STATUS</label>
            <div className="data-entry-input --add-user-input">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <select
                    {...field}
                    className="data-entry-input-field --select-option"
                  >
                    <option value="">SELECT STATUS</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                )}
              />
            </div>
          </div>
        </div>
        <div className="viewUserBtnContainer py-3">
          <button
            className="add-user-submit-btn"
            onClick={() => {
              navigate("/editUser", { state: value });
            }}
          >
            EDIT
          </button>
          <button className="add-user-submit-btn" onClick={togglePopup}>
            DELETE
          </button>
        </div>
      </form>

      
    </>
  );
};

export default ViewUserForm;
