import React from 'react'
import AddUserForm from '../../common/components/addUserFormComponent/AddUserForm'
import Strings from '../../common/res/String'
import Sidebar from '../../common/components/Sidebar/sidebar'
import FormHeader from '../../common/components/FormHeader/FormHeader'
import ViewUserForm from '../../common/components/viewUserFormComponent/ViewUserForm'

const ViewUser = () => {
  return (
<>
<div className="container-fluid">
        <div className="row">
          <div className="col-3 p-0">
            <Sidebar/>
          </div>
          <div className="col-8 p-0">
            <div className="addUserMainContainer my-4">
              <FormHeader header={Strings.viewuser} />
              <div className="add-user-form-block">
               <ViewUserForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
</>
  )
}

export default ViewUser