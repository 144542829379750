import React from "react";
import background from "./img/bg.png";
import Applogo from "./img/logo.png";
import emailIcon from "./img/email.png";
import passwordIcon from "./img/Password.png";
import hidepassword from "./img/hideps.png";
import showpassword from "./img/showps.png";
import backArrow from "./img/back.png";
import editIcon from "./img/pencil.png"
import searchIcon from './img/search.png'
// import email from "./svg/email";
// import passwordLockIcon from "./svg/passwordLockIcon";
// import passwordVisible from "./svg/passwor, m,m,.m,, ,dVisible";
// import AddIcon from "./svg/AddIcon";
// import deleteIcon from "./svg/deleteIcon";
// import EditIcon from "./svg/EditIcon";
const Images = {
  bg: background,
  logo: Applogo,
  emails: emailIcon,
  password: passwordIcon,
  hidepasswords:hidepassword,
  showpasswords:showpassword,
  goback:backArrow,
  editpencil:editIcon,
  searchIcons:searchIcon
  // passwordVisibleIcon: passwordVisible,
  // adduserIcon: AddIcon,
  // deleteUserIcon: deleteIcon,
  // editPencilIcon: EditIcon,
};
export default Images;
