import React, { useEffect, useState } from "react";
import Images from "../../common/assets";
import { Controller, useForm } from "react-hook-form";
import InputField from "../../common/components/inputField/InputField";
import { Modal } from "@mui/material";

export default function Otp({ view,setView }) {
    const [timeLeft, setTimeLeft] = useState(30);
    const[isInvalid,setIsInvalid]=useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const maskEmail = (props) => {
    console.log("SESSIONSTORAGER", props);
    let email = sessionStorage.getItem("email");
    const [name, domain] =email&&email.split("@");
    const maskedName = name.slice(0, 5) + "***" + name.slice(-1);
    return `${maskedName}@${domain}`;
  };
  let otpcheck=sessionStorage.getItem("OTP")
  const{otpfiled}=watch()
  const onSubmit=(data)=>{

    console.log("LOGINDATA",otpfiled,"otpcheck",otpcheck);
    if(Number(otpcheck)==Number(otpfiled)){
      setView("reset");
      sessionStorage.setItem("view", "reset")
      
    }else{
      setIsInvalid(true)
      console.log("FALSE");
    }
    // let value={
    //   username:data?.username,
    //   password:data?.password
    // }
    // mutate(value)xx
  } 

   useEffect(()=>{
    if (view === "OTP"){
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    
      return () => clearInterval(timer);
    
    }
    }, [view]);
    const resendOtp = () => {
        // // Resend OTP to server
        setTimeLeft(30);
        setValue("otpfiled",'')
      };

      const handleForgetPasswordClick = () => {
        sessionStorage.removeItem("OTP")
        sessionStorage.removeItem("email")
        sessionStorage.setItem("view", "forget")
        setView("forget");
      };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={handleForgetPasswordClick}
        >
          <img
            src={Images.goback}
            alt="goback"
            style={{ width: "100%", padding: "3px" }}
          />
          {/* <a href="https://www.flaticon.com/free-icons/back" title="back icons">Back icons created by Freepik - Flaticon</a> */}
        </div>
        <h2>Verification</h2>
      </div>
      <p style={{ margin: 0, marginTop: "20px" }}>
        Enter the OTP code we sent you
      </p>
      <p style={{ margin: 0, marginBottom: "20px" }}>
        {view === "OTP" ? maskEmail(sessionStorage.getItem("email")) : ""} not
        yours?
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="omrs-input-group">
          <Controller
            control={control}
            name="otpfiled"
            render={(field) => 
            
              <InputField
              inputField_otp='inputField_otp'
              // dataEntryInputField="data-entry-input-field"
              {...field}
              type='Number'
              placeholder="Password"
            />
            }
          />

          {timeLeft > 0 ? (
            <p className="resend_label_otp">
              Resend in {`00:${timeLeft < 10 ? `0${timeLeft}` : timeLeft}`}
            </p>
          ) : (
            <button onClick={resendOtp} className="reset_btn">
              Resend OTP
            </button>
          )}
        </div>
        <div>
          {/* {timeLeft > 0 ? (
        <span>Resend in {timeLeft} seconds</span>
      ) : (
        <button onClick={resendOtp} disabled={timeLeft > 0}>
          Resend OTP
        </button>
      )} */}
        </div>
        <button type="submit" className="login_overall_btn">
          CONFIRM
        </button>
      </form>

      <Modal
  open={isInvalid}
  onClose={()=>setIsInvalid(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
 <div className="model_error">
  <h3>OTP is in Valid</h3>
 </div>
</Modal>
    </>
  );
}
