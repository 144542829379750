import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import Strings from "../../common/res/String";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import Model from "../../common/components/model/Model";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import * as FileSaver from "file-saver";
const Summary = () => {
  const [isCount, setIsCount] = useState(7);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmModal = () => {
    // Perform delete operation
    console.log("User detail deleted");
    setModalOpen(false);
  };
  const[isProcessing,setIsProcessing]=useState(false)
  const handleDownloadAll = async () => {
    setIsProcessing(true);
    const zip = new JSZip();

    try {
      // Convert the content of the page to PDF
      const pageContent = document.getElementById("summary_page");
      if (pageContent) {
        const canvas = await html2canvas(pageContent);
        const imgData = canvas.toDataURL("image/png");

        // Create PDF with proper dimensions
        const pdf = new jsPDF("p", "pt", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 30, pdfWidth, pdfHeight);

        // Save the PDF directly and convert to Base64
        const pdfBase64 = pdf.output("datauristring").split(",")[1];
        zip.file("Summary.pdf", pdfBase64, { base64: true });
      }

      // Add Excel file to the ZIP file
      if (retrievedData?.sblcDocument) {
        zip.file("File.xlsx", retrievedData?.sblcDocument, { base64: true });
      }

      // Generate the ZIP file and trigger download
      const content = await zip.generateAsync({ type: "blob" });
      FileSaver.saveAs(content, "Summary.zip");
    } catch (error) {
      console.error("Error during download:", error);
    } finally {
      setIsProcessing(false);
    }
  };
  // const handleDownloadAll = async () => {

  //   setIsProcessing(true);
 

  //   try {
  //     // Convert the content of the page to PDF
  //     const pageContent = document.getElementById("summary_page");
  //     if (pageContent) {
  //       const canvas = await html2canvas(pageContent);
  //       const imgData = canvas.toDataURL("image/png");

  //       // Create PDF with proper dimensions
  //       const pdf = new jsPDF("p", "pt", "a4");
  //       const imgProps = pdf.getImageProperties(imgData);
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //       const topGap = 48;
  //       pdf.addImage(imgData, "PNG", 0, topGap, pdfWidth, pdfHeight);
  //       pdf.save("Summary.pdf");
  //       // Save the PDF directly and convert to Base64
  
  //     }


  //   } catch (error) {
  //     console.error("Error during download:", error);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };
  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  console.log("retrievedData", retrievedData);
  return (
    <>
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>

          <div className="col-lg-10">
            <div className="row align-items-center">
              <div className="headerTimeLine px-5">
                <TimeLine currentStep={isCount} />
              </div>
            </div>
            <div className="row " style={{ paddingLeft: "3rem" }}>
              <div className="col-lg-9 p-0 m-0 d-flex align-items-center justify-content-center">
                <div className="summaryMainContainer p-0 m-0">
                <div id="summary_page">
                  <div className="summary-head px-4">
                    <label className="summary-label">{Strings.beno}</label>
                    <div style={{ width: "35%" }}>
                      <input
                        type="search"
                        name="beno"
                        className="billNoSearchField --hiddens"
                        placeholder="3421631"
                        disabled
                        value={retrievedData&&retrievedData?.beNo}
                      />
                    </div>
                  </div>
                  <div className={`${retrievedData&&retrievedData?.userForm.length>5?"summary-table-container":"summary-table-container_single"} mt-2 px-4 pt-3`}>
                    <table className={`${retrievedData&&retrievedData?.userForm.length>5?"summary-table":"summary-table_single"}`}>
                      <thead className="summary-table-head">
                        <tr>
                          <th>S.no</th>
                          <th>CONTAINER NO</th>
                          <th>LENGTH</th>
                          <th>AVG CFT/AVG GIRTH</th>
                          <th>PCS</th>
                          <th>CBM</th>
                        </tr>
                      </thead>
                      <tbody>
                        {retrievedData&&retrievedData?.userForm
                          .map((ele, index) => (
                            <tr key={index}>
                              <td>{index+1}</td>
                              <td>{ele?.containerNo}</td>
                              <td>{ele?.averageLength}</td>
                              <td>{ele?.avgCftAvgGirth}</td>
                              <td>{ele?.pcs}</td>
                              <td>{ele?.grossCbm}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                  <div className="summary-download"   onClick={() => {
                        handleDownloadAll()
                      }}>
                    <button
                      className="summary-download-btn"
                      onClick={() => {
                        handleDownloadAll()
                      }}
                    >
                      {
                        isProcessing?"Loading":"Download"
                      }
                    </button>
                    {/* <Model
                      open={isModalOpen}
                      handleClose={handleCloseModal}
                      handleConfirm={handleConfirmModal}
                    ></Model> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-0 right_col">
                <div className="headerTimeLine_left">
                  <RightSidebar setIsCount={setIsCount} islabel='SUMMARY'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
