import React from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar';

export default function TimeLine({ currentStep }) {
    const totalSteps = 10;
    return (
        <div className="step-progress px-4">
          <ProgressBar
            percent={(currentStep - 1) / (totalSteps - 1) * 100}
            filledBackground="#91B90B"
          >
           {Array.from({ length: totalSteps }, (_, index) => {
          const stepNumber = index + 1;
          return (
            <Step key={index}>
              {({ accomplished }) => (
                <div
                  className={`indexedStep ${
                    stepNumber < currentStep
                      ? 'previous'
                      : stepNumber === currentStep
                      ? 'current'
                      : stepNumber === currentStep + 1
                      ? 'next'
                      : ''
                  }`}
                >
                  {stepNumber}
                </div>
              )}
            </Step>
          );
        })}
          </ProgressBar>
        </div>
      );
    
}
