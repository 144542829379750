import React from "react";

const PopUpModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="popup" onClick={onClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </>
  );
};

export default PopUpModal;
