import React from 'react'
import Strings from '../../common/res/String'
import Sidebar from '../../common/components/Sidebar/sidebar'
import FormHeader from '../../common/components/FormHeader/FormHeader'
import EditUserForm from '../../common/components/editUserFormComponent/EditUserForm'

const EditUser = () => {
  return (
   <>
   <div className="container-fluid">
        <div className="row">
          <div className="col-3 p-0">
            <Sidebar />
          </div>
          <div className="col-8 p-0">
            <div className="addUserMainContainer my-4">
              <FormHeader header={Strings.edituser} />
              <div className="add-user-form-block">
                <EditUserForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default EditUser