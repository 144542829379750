import React from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";
import FormHeader from "../../common/components/FormHeader/FormHeader";
import Strings from "../../common/res/String";
import AddUserForm from "../../common/components/addUserFormComponent/AddUserForm";

const AddUser = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 p-0">
            <Sidebar />
          </div>
          <div className="col-8 p-0">
            <div className="addUserMainContainer my-4">
              <FormHeader header={Strings.adduser} />
              <div className="add-user-form-block">
                <AddUserForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
