import React, { useState } from "react";
import Images from "../../common/assets";
import InputField from "../../common/components/inputField/InputField";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useMutation } from "react-query";
import { Modal } from "@mui/material";

export default function Reset() {
  const [password, setPassword] = useState("password");
  const[isInvalid,setIsInvalid]=useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [typepassword, setTypePassword] = useState("password");
  const [typespassword, setTypesPassword] = useState("password");
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/users/resetpassword`,
        postData.body,
        {
            params: postData.params,  // The params object
          }
      ),
    {
      onSuccess: (data) => {
        sessionStorage.removeItem("OTP")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("view")
        window.location.reload()
        // sessionStorage.setItem("view", "login")
      },
      onError: (error) => {
        // alert("Login is Not Successfully")
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    console.log("LOGINDATA", data);
    let value = {
      emailId: sessionStorage.getItem("email"),
      password: data?.password,
      confirmPassword:data?.confirmpassword
    };
    mutate({
        // body: value,
        params: {   emailId: sessionStorage.getItem("email"),
            password: data?.password,
            confirmPassword:data?.confirmpassword }  // Replace with your actual params
      });
    // mutate({params:value});
  };
  const confirmemail=()=>{
    sessionStorage.removeItem("OTP")
    sessionStorage.removeItem("email")
    sessionStorage.setItem("view", "login")
  }
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={confirmemail}
        >
          <img
            src={Images.goback}
            alt="goback"
            style={{ width: "100%", padding: "3px" }}
          />
          {/* <a href="https://www.flaticon.com/free-icons/back" title="back icons">Back icons created by Freepik - Flaticon</a> */}
        </div>
        <h2>Reset your Password</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-container">
          <Controller
            control={control}
            name="password"
            render={(field) => (
              <InputField
                {...field}
                type={`${typepassword !== "password" ? "Text" : "password"}`}
                placeholder="Password"
              />
            )}
          />

          <img
            src={`${
              typepassword == "password"
                ? Images.hidepasswords
                : Images.showpasswords
            }`}
            className="iconpass"
            onClick={() => {
              setTypePassword(
                `${typepassword == "password" ? "Text" : "password"}`
              );
            }}
          />
        </div>
        <div className="input-container">
          <Controller
            control={control}
            name="confirmpassword"
            render={(field) => (
              <InputField
                {...field}
                type={typespassword}
                placeholder="Confirm Password"
              />
            )}
          />

          <img
            src={`${
              typespassword == "password"
                ? Images.hidepasswords
                : Images.showpasswords
            }`}
            className="iconpass"
            onClick={() => {
              setTypesPassword(
                `${typespassword == "password" ? "Text" : "password"}`
              );
            }}
          />
          {/* <i className="fas fa-lock icon"></i> */}
        </div>

        <button type="submit">Submit</button>
      </form>

 
    </>
  );
}
