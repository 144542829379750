import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";

import UserManagementView from "../../common/components/userManagementComponents/UserManagementView";

import { useNavigate } from "react-router-dom";
const UserManagement = () => {
  const navigate = useNavigate();
 
  // const handleChange = (e) => {
  //   setSearchName(e.target.value);
  // };
  // const filteredProducts = tableData?.filter((product) => {
  //   return product?.userId?.toLowerCase().startsWith(searchName?.toLowerCase())||product?.name?.toLowerCase().startsWith(searchName?.toLowerCase())
  // });
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>
          <div className="col-sm-10 col-lg-10">
            <div className="row">
              <div className="viewUserMainContainer p-0">
                <UserManagementView/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
