import React from 'react'
import Sidebar from '../../common/components/Sidebar/sidebar'
import ForwardCoverTable from '../../common/components/ForwardCoverTable/ForwardCoverTable'

export default function ForwardCover() {
  return (
//     <div className="container-fluid">
//     <div className="row">
//       <div className="col-3 p-0">
//         <Sidebar />
//       </div>
//       <div className="col-sm-9 col-lg-9 p-0">
//             <div className="row">
//               <div className="viewUserMainContainer p-0">
//                 <ForwardCoverTable/>
//               </div>
//             </div>
//           </div>
//     </div>
//   </div>
<div className="container-fluid">
<div className="row">
  <div className="col-lg-2 p-0 col-md-3">
    <Sidebar />
  </div>
  <div className="col-sm-10 col-lg-10">
    <div className="row">
      <div className="viewUserMainContainer p-0">
        <ForwardCoverTable/>
      </div>
    </div>
  </div>
</div>
</div>
  )
}
