import logo from './logo.svg';
import Images from './common/assets/index'
import Strings from './common/res/String';
import Navigation from './common/navigation/navigation';
import Sidebar from './common/components/Sidebar/sidebar';

function App() {
  return (
  <Navigation/>
  // <Sidebar/>
  );
}

export default App;
