import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Demo() {
  const mimeType = "image/jpeg"; // MIME type for JPEG image
  const fileName = "image.jpg";
  const base64String = `/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDh
IQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUF
BQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCABkAGQDASIAAhEBAxEB/8QAGg
ABAAMBAQEAAAAAAAAAAAAAAAMGBwgFBP/EABoBAQACAwEAAAAAAAAAAAAAAAADBAECBQb/2gAMAwEAAhA
DEAAAAeaBDsAAAAAAAAAAAAAAtFXv8Etipke4c+zzds2h5PjHtYpp1CtReKL1cAC3b5yx0Zx78d/rk3Ms
adl+P61s+fyfqyGbamD0fGAAAliGUkYkjAGAAAAAAAAAAAAAAAP/xAAhEAACAwACAQUBAAAAAAAAAAADBQI
EBgEHIAAQEhNQFf/aAAgBAQABBQL9dTnrLbiOChKLjM20/FeuW2Zd1hYPBj1ZYCKzWLTP5LNYWhQ51DP7Mo6
jpKmXyokJX3YF63aT9h36c27Yzq945ScIPr56asHGrz/rPXl7WIjBNG1rUNcw9Qntm1UxDTeMZcwkh09F1Vj1
8mmRzq1uXoZXXkUXL65Vo4iXrkENG9/rF8/tn8faBJC5mSROf2P/xAAhEQACAQMDBQAAAAAAAAAAAAABAgMA
ERMhIjEEEiAwQP/aAAgBAwEBPwH3x9t99AQy7V0NY1jF5KkxkXTw6dxYrV2XV7UrmUbOakfHGQ/J+j//xAAkE
QACAQMDAwUAAAAAAAAAAAABAwIABBESQVEUICEiIzAxQP/aAAgBAgEBPwH53Fgj7X3U53NuNczkUbhtzPRb+A
N6R1EZGLvI57L5RMgzbepBbfQjJNSthbzw3OORSFBjQVZ0jn9H/8QANhAAAgEDAQMHCQkAAAAAAAAAAQIDAAQREh
MhURQgIjEyQWEFQlBSU3GBoeEQIzA0Y4PB0fD/2gAIAQEABj8C9L61xFD7R+/3V+cbVx2f1rWw2sHtE/mlihQySNuCr
Qa7ulgPqIuqi9pdrO3s5F0/OnhmQxyocFW55gaPasoxEx7vfWvlTDwAGKlguUXbKMOO5hV5O2CS5CMfNjp0sZTa2qnCl
e03jWm9Y3sHj2h8ae5nxqO4AdSjhzrbXjDZXfxIOKE1yn3ecZCZremf2KeWxh0qvRL7LTUgUh9DaGHjTxOgjkQ6WU2/V8qS
KEhpHOAohP8AVXPRXJwo3ePODKcMN4Irk18UjmI0ssnZetph9HqCTo1yXyfs5LgDCRxdlPE1Nypmkt7htUh7w3rULnQk/wCtE2
/4/WjKqLb8ZZDv/wB7qEcWRbR9WfOPH8DTrbTwz9uUYqeINZdix4k+mf/EACgQAQABAgUDAwUBAAAAAAAAAAERACExQ
VFhcYGRsSBQoRDB4fDxMP/aAAgBAQABPyH3eGEmMjgz8b1CegI7Uj+kjKOGVO9+GlWnIm99OWQ80SOE3F4kneKgB4aEf
WaueSDZqDLtwmNkzAdkRS/Vk7+0x5rQsYcv/eKWEAEh1OXBTdAG0BsozdaaPZmcB6hAqL2F8ooWYhht3go2F+
21TKjml0nOiE6Y6AXHvTHMoRRQE4L5tEeMYIuo+0vT1P8AGmIDrQq2sxvk26VbdlP6T80+mlek1Txi0DZiqv5b/i
nQhIsuCP4oRUkLqNBfGKr7AnCdR9vz/gHAG4j68dvTXN50+8//2gAMAwEAAgADAAAAEP8A/wD/AP8A/wD/AP8A/
wD/AP8A/wD+3S4//wDxr7Vv/wD/AP8Avnv/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/xAAeEQEAAgEE
AwAAAAAAAAAAAAABABEhIDFBUTBAYf/aAAgBAwEBPxDzh5sRyh4ID514lJYPWgCUF2uF9QHUvNfhiITaA03fp//EA
CIRAQABAgUFAQAAAAAAAAAAAAERADEgIVFhsTBAQXGRof/aAAgBAgEBPxDr5FDuqDTzmn4U0UgSt/vFFgIMhx44+
4AmKMgXjWowW6xB7pPKdtSh+kSuz1hALUk3oIt2P//EACMQAQEAAgIBBAMBAQAAAAAAAAERACExQVEgYXGBUJGhEL
H/2gAIAQEAAT8Q/LhDFRNB2Ftm96CRDkA1+QX+z+4snqFVeBNt+z3wT1/pLf1eAK4ZxRbb6UD8A98UTIfC4IX2D3x
++PlbHhIiaRE0+sT14w3jI0LCPZkccPLC326nyYQjYdXYrjsHWnuHOSaCQBXha13OSVIBRlYtckIO7nWh2f
NCaLBL1wkiH0INKHmQrt2qrz6mUDIEhBe1j7xijWOwzYgyV1YdmKHuy3/3EFUQim5g00ocUwHQLgE
i44FHziZ3ARRGeWAXmwUYeIdq6AVx+JFE8LZyD4F6n57NBNA9IlyZLoh8V0LzcR4uA72qNPF6YBpKIY9mo
72U5luKgKDfNg8rAbQE3CNBoROaR2hqBYmsY/HuJEXnwD4OsAqpg0cS6JQOwVYoPXoYEnV+LP8ASpk
Sr9w4jatKm+38z//Z`;

  function base64ToFile(base64String, mimeType, fileName) {
    // Remove data URL scheme if present
    const base64Data = base64String.replace(/^data:.+;base64,/, "");
    const byteCharacters = atob(base64Data); // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = URL.createObjectURL(blob);

    console.log('byteArray',byteArray);
    

    // Create a link element to download the file
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  }
  return (
    <div>
      <h1>Base64 to Image File Conversion</h1>
      <button id="download" onClick={()=>{
        base64ToFile(base64String, mimeType, fileName)
      }}>Download Image File</button>
    </div>
  );
}

// import React from 'react';
// import jsPDF from 'jspdf';

// const convertImageToPDFBase64 = async (base64Image) => {
//   // Create a new PDF document
//   const doc = new jsPDF();

//   // Convert base64 image to a Blob
//   const imgBlob = await fetch(`data:image/png;base64,${base64Image}`).then(res => res.blob());

//   // Create an image element
//   const img = new Image();
//   img.src = URL.createObjectURL(imgBlob);

//   // Wait for the image to load
//   await new Promise((resolve) => {
//     img.onload = () => resolve();
//   });

//   // Add the image to the PDF
//   doc.addImage(img, 'PNG', 10, 10, 180, 0);

//   // Save the PDF as a base64-encoded string
//   const pdfBase64 = doc.output('datauristring');

//   // Return the base64-encoded PDF
//   return pdfBase64;
// };

// const Demo = () => {
//   const base64Image = 'YOUR_BASE64_IMAGE_STRING_HERE';

//   const handleConvert = async () => {
//     const pdfBase64 = await convertImageToPDFBase64(base64Image);
//     console.log(pdfBase64); // Base64 encoded PDF string
//   };

//   return (
//     <div>
//       <button onClick={handleConvert}>Convert Image to PDF</button>
//     </div>
//   );
// };

// export default Demo;
