// FileUploadSection.js
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { Button, Drawer } from "@material-ui/core";

const FileUploadSection = ({
  control,
  handleFileChange,
  fileList,
  removeFile,
  arrayName,
  drawerState,
  toggleDrawer,
  states,
}) => {
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  console.log("states_COPY", Array(states).length);
  return (
    <>
      <Controller
        name={arrayName}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={(e) => {
              handleFileChange(e, arrayName);
              field.onChange(e);
            }}
            style={{ display: "none" }}
          />
        )}
      />
      <div className="dataEntryAddCopyDocument my-4" onClick={triggerFileInput}>
        <div className="dataEntryAddInnerCopyDocument">
          <h6 className="data-entry-image-head">
            ADD {arrayName.toUpperCase().replace("DOC", " DOCUMENT")}
          </h6>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        {fileList?.[arrayName]?.slice(0, 3).map((file, index) => (
          <div key={index} className="upload_block d-flex">
            <span style={{ marginRight: "10px" }}>{file?.name}</span>
            <button
              type="button"
              onClick={() => removeFile(arrayName, file?.name,index)}
              className="file-remove-button"
            >
              X
            </button>
          </div>
        ))}
        {states&&states? (
          Array(states).map((ele, index) => {
            console.log('====================================');
            console.log("INDEXXX",index);
            console.log('====================================');
            return (
              <div key={index} className="upload_block d-flex">
                <span style={{ marginRight: "10px" }}>{`Copydoc doc${
                  index > 0 ? index + 1 : ""
                }`}</span>
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  className="file-remove-button"
                >
                  X
                </button>
              </div>
            );
          })
        ) : (
          <></>
        )}
        {fileList?.[arrayName]?.length > 3 && (
          <React.Fragment>
            <Button onClick={toggleDrawer(arrayName, true)}>
              +{fileList?.[arrayName]?.length - 3}
            </Button>
            <Drawer
              anchor="right"
              open={drawerState[arrayName]}
              onClose={toggleDrawer(arrayName, false)}
            >
              <div className="m-2">
                <h6 className="px-1" style={{ color: "brown" }}>
                  DATA ENTRY FORM DOCUMENT
                </h6>
                {fileList?.[arrayName]?.map((file, index) => {
                  console.log("FILENAME", fileList);
                  return (
                    <>
                      <div key={index} className="upload_block d-flex">
                        <span style={{ marginRight: "10px" }}>
                          {file?.name}
                        </span>
                        <button
                          type="button"
                          onClick={() => removeFile(arrayName, file?.name)}
                          className="file-remove-button"
                        >
                          X
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
              <div style={{ padding: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={triggerFileInput}
                >
                  Add Files
                </Button>
              </div>
            </Drawer>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default FileUploadSection;
