import React, { createContext, useState, useContext } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState({
    page1Content: null,
    page2Content: null,
    page3Sheet1Content: null,
    page3Sheet2Content: null,
  });
//   const setPage1Content = (content) => {
//     setState((prevState) => ({
//       ...prevState,
//       page1Content: content,
//     }));
//   };

//   const setPage2Content = (content) => {
//     setState((prevState) => ({
//       ...prevState,
//       page2Content: content,
//     }));
//   };

//   const setPage3Content = (content) => {
//     setState((prevState) => ({
//       ...prevState,
//       page3Content: content,
//     }));
//   };
const setPageContent = (page, content) => {
    setState((prevState) => ({
      ...prevState,
      [page]: content,
    }));
  };
  return (
    <GlobalStateContext.Provider
     value={{ ...state, setPageContent }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
