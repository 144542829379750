import React from 'react';

const Footer = () => {
  return (
    <footer className="footer mt-3">
      <div className="container p-0">

        <div className="row">
          <div className="col-md-12 text-center">
          <div className='footerlabels'>
            <p style={{color:"#fff",margin:0}}>
              Dealers in : Teak Wood | Round Logs | Sawn Sizes
            </p>
          </div>
            <p style={{color:"#ADADAD",margin:0,padding:5}}>
              New # 515, Old # 274, Sydenhams Road, Nehru Timber Market, Choolai, Chennai - 600 112.
              <br />
              Phone: +91 44 4282 5199/2669 0199 | Mobile: +91 98407 28399
              <br />
              Email: besttimbers@gmail.com | Web: www.besttimbers.com
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;