import React, { useEffect, useState } from 'react'
import Strings from '../../common/res/String'
import Images from '../../common/assets'
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import axios from 'axios';
import InputField from '../../common/components/inputField/InputField';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
export default function Forget({setView,view}) {
  const[isenabel,setIsEnable]=useState(false)
  // const[emailId,setemailId]=useState('')
  const navigate = useNavigate();
  const {
      control,
      handleSubmit,
      setValue,
      reset,
      watch,
      formState: { errors },
    } = useForm();
  const{email}=watch()
  // const[]
      const confirmemail = () => {
        setIsEnable(true)
        sessionStorage.setItem("email", email)
        // sessionStorage.setItem("email", e.target.value)
        // setemailId(email)
        // 
      };


      // RESET EMAIL API START
const getUserDetails = async (emailId) => {
  return await axios.get(`${process.env.REACT_APP_HOST}/users/sendMail/${emailId}`)
}
// const emailsendotp = useQuery(
//   ["emailsendotp"],
//   getUserDetails
// );
// let emailId=

const emailsendotp = useQuery(
  ["emailsendotp"],
  () => getUserDetails(email),
  {
    enabled: isenabel, 
    onSuccess: (data) => {
      console.log("OTPFILEDDDDDD><<<<<<",data&&data?.data.replace("MAIL SENT :", "",data));
      sessionStorage.setItem("view", "OTP")
      sessionStorage.setItem("OTP", data&&data?.data.replace("MAIL SENT :", ""))
      setIsEnable(false)
      window.location.reload()
       },
    onError:(error)=>{
      alert("OTP is Not Successfully")
    }
  }
);
// RESET EMAIL API END
    
  return (
    <>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{ width: "20px", height: "20px", cursor: "pointer" }}
        onClick={() =>  (sessionStorage.removeItem("view"),setView("login"),sessionStorage.removeItem("email"))}
      >
        <img
          src={Images.goback}
          alt="goback"
          style={{ width: "100%", padding: "3px" }}
        />
        {/* <a href="https://www.flaticon.com/free-icons/back" title="back icons">Back icons created by Freepik - Flaticon</a> */}
      </div>
      <h2>{Strings.forgot_password}</h2>
    </div>
    <p>Enter your Email address</p>
    <form onClick={handleSubmit(confirmemail)}>
      <div className="input-container">
        {/* <img src={Images.emails} className="icon" />
        <input
          type="email"
          placeholder={Strings.email_address}
          value={sessionStorage.getItem("email")}
          onChange={(e) => sessionStorage.setItem("email", e.target.value)}
          required
        /> */}

        <img src={Images.emails} className="icon" />
        <Controller
          control={control}
          name="email"
          render={(field) => (
            <InputField
             {...field}
              type="text"
              placeholder={Strings.email_address}
            />
          )}
        />
      </div>

      <button type="submit" >
        CONFIRM
      </button>
    </form>
  </>
  )
}
