import React, { useEffect, useRef, useState } from "react";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import Sidebar from "../../common/components/Sidebar/sidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// const base64ToPdfUrl = (base64String) => {

//   // Decode the Base64 string
//   const byteCharacters = atob(base64String);
//   const byteNumbers = new Array(byteCharacters.length);
//   for (let i = 0; i < byteCharacters.length; i++) {
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }
//   const byteArray = new Uint8Array(byteNumbers);

//   // Create a Blob from the byteArray
//   const blob = new Blob([byteArray], { type: 'application/pdf' });
//   return URL.createObjectURL(blob);
// };
const base64ToPdfUrl = (base64String) => {
  if (!base64String) {
    console.error("Base64 string is undefined or empty");
    return null;
  }

  try {
    // Decode base64 string
    const byteCharacters = atob(base64String);

    // Convert to byte array
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob and URL
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error processing base64 string:", error);
    return null;
  }
};
const CopyDocumentFileShow = () => {
  const { state } = useLocation();
  const [isCount, setIsCount] = useState(state?.count ? state?.count : 1);
  const imageDownload = useRef();
  // Example Base64 string (replace with your own)
  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));
  const [pdfUrl, setPdfUrl] = useState("");
  console.log('pdfUrl',pdfUrl);

  console.log("stateimgValue", state?.files);
  useEffect(() => {
    const url = base64ToPdfUrl(state?.files);
    console.log("imangeurl",url);
    
    setPdfUrl(url);
  }, [state]);

  const handleGeneratePdf = async () => {
    // alert("Download Start");
    const pdf = new jsPDF("p", "mm", "a4");
    const options = {
      scale: 2,
      useCORS: true,
    };

    const captureDiv = async (id) => {
      const element = document.getElementById(id);
      if (element) {
        const canvas = await html2canvas(element, options);
        return canvas.toDataURL("image/png");
      }
      return null;
    };

    const imgData1 = await captureDiv("sheet1-content");
    const imgData2 = await captureDiv("sheet2-content");
    const imgData3 = await captureDiv("sheet3-content");
    const imgData4 = await captureDiv("sheet4-content");
    const imgData5 = await captureDiv("sheet5-content");
    const imgData6 = await captureDiv("sheet6-content");

    const imgWidth = 210; // A4 width in mm
    const addImageToPdf = (imgData, addPage = false) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imgData;
        img.onload = () => {
          const imgHeight = (img.height * imgWidth) / img.width;
          if (addPage) pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
          resolve();
        };
      });
    };

    if (imgData1) await addImageToPdf(imgData1);
    if (imgData2) await addImageToPdf(imgData2, true);
    if (imgData3) await addImageToPdf(imgData3, true);
    if (imgData4) await addImageToPdf(imgData4, true);
    if (imgData5) await addImageToPdf(imgData5, true);
    if (imgData6) await addImageToPdf(imgData6, true);
    pdf.save("ContainerImage.pdf");
  };

  const groupedItems = [];
  const itemsPerPage = 4;

  for (let i = 0; i < state.imgValue?.length; i += itemsPerPage) {
    groupedItems.push(state.imgValue?.slice(i, i + itemsPerPage));
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2 p-0 col-md-3">
          <Sidebar />
        </div>

        <div className="col-sm-10 col-lg-10">
          <div className="row align-items-center">
            <div className="headerTimeLine px-5">
              <TimeLine currentStep={isCount} />
            </div>
          </div>
          <div className="row" style={{ paddingLeft: "3rem" }}>
            <div
              className="col-lg-9"
              style={{
                paddingTop: "4px",
                height: "calc(100vh - 20px)",
                overflow: "scroll",
                paddingBottom: "3rem",
              }}
            >
              <div>
                {/* <button onClick={handleShowPdf}>Show PDF</button> */}
                { pdfUrl ? (
                  <iframe
                    src={pdfUrl}
                    // type="application/pdf"
                    width="100%"
                    height="800"
                    style={{
                      border: "none",
                      overflow: "hidden",
                      background: "#fff",
                    }}
                  />
                ) : (
                <>
                <div
                    style={{
                      // height: "1310px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    {groupedItems?.map((group, groupIndex, index) => {
                      console.log("group", groupIndex + 1);
                      return (
                        <div
                          id={`sheet${groupIndex + 1}-content`}
                          // key={groupIndex}
                          style={{
                            height: "1310px",
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "20px",
                          }}
                        >
                        <div >
                          {group?.map((val, i) => (
                            <img
                              key={i}
                              style={{

                                height: "450px",
                                width: "300px",
                                margin: "30px",
                              }}
                              src={`data:image/png;base64,${val}`}
                              alt=""
                            />
                          ))}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-center py-5">
        <button
          className="summary-download-btn"
          onClick={() => handleGeneratePdf()}
        >
          Download
        </button>
      </div>
                </>
                )}
              </div>
            </div>
            

            <div className="col-lg-3 p-0 right_col">
              <div className="headerTimeLine_left">
                {/* <SimpleBar style={{ maxHeight: 300 }}> */}

                <RightSidebar
                  setIsCount={setIsCount}
                  islabel={`${
                    isCount == 10 ? "FILE DOCUMENT" : "COPY DOCUMENT"
                  }`}
                />
                {/* </SimpleBar> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyDocumentFileShow;
