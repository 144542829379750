import React from "react";

const InputField = (props) => {
  // console.log('====================================');
  // console.log("autofocus",props);
  // console.log('====================================');
  return (
    <>
      <input
        className={`${props?.dataEntryInputField}
        ${props?.billNoSearchField}
        ${props?.addUserPassword}
        ${props?.inputField_otp}
        ${props?.error_message}
        
         `}
        type={props.type}
        step={props.step}
        autofocus={props?.autofocus}
        // checked={props.checked}
        placeholder={props.placeholder}
        // disable=
        disabled={props?.disable}
        {...props.field}
      ></input>
    </>
  );
};

export default InputField;
